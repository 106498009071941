.cmedia .showImg {
  display: none;
  background-color: rgba(172, 171, 171, 0.59);
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.cmedia:hover .showImg {
  display: flex;
}
.sold {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
}
.sold img {
  user-select: none;
}
.dselect > .MuiSelect-icon{
color: white;
}
.dp-divider::before, .dp-divider::after {
  top: 0 !important;
}